import React from "react"
import { withPrefix } from "gatsby"

// images:
import persona1 from "../images/ECS_Persona_2000x1000.png"

/* jshint ignore:start */
function FoundationSection() {
  return (
    <section className="text-left" id="foundation">
      <div className="bg-white sticky top-0">
        <h2 className="heading-1" tabIndex="0">
          Brand Foundation
        </h2>
      </div>
      <p className="my-4 md:w-5/6" tabIndex="0">
        Brand is the way a company is{" "}
        <span className="font-bold italic">perceived</span> by those who
        experience it. Much more than a name or logo, a brand is a recognizable
        feeling derived from the core truth of the company. You can’t hold it or
        hear it or even touch it. Brands live in the minds of the people who
        experience them: staff, care partners, benefactors, and, most
        importantly, prospective and current residents and their families.
      </p>

      {/* ESSENCE */}
      <h3 className="heading-2" tabIndex="0">
        Essence
      </h3>
      <p className="my-4 md:w-5/6" tabIndex="0">
        The ECS Brand Essence is the soul of our brand. It’s the “why” behind what
        we do and the
        <span className="font-bold italic"> driving force</span> of the brand
        expression. We believe there’s nothing more empowering than crafting
        life the way you want it. Every step of life’s journey is a unique
        opportunity to reignite the future with hope and confidence.
      </p>
      <p
        className="font-primary_font text-primary_color messaging-text-xl bg-grayBG p-4 md:pr-20 my-4"
        tabIndex="0"
      >
        The Art of Possibility
      </p>

      {/* PROMISE */}
      <h3 className="heading-2" tabIndex="0">
        Promise
      </h3>
      <p className="my-4 md:w-5/6" tabIndex="0">
        The Brand Promise is the{" "}
        <span className="font-bold italic">experience</span> our customers can
        expect every time they interact with one of us or with an expression of
        the brand itself.
      </p>
      <p className="my-4 md:w-5/6" tabIndex="0">
        At ECS life’s journey can be as adventurous or easy-going as you want it
        to be. Open the door to a life full of possibility, and find the choices
        you deserve, the freedoms you desire, and the flexibility you can count
        on. Keep your edge, stay active, and create the future you want— because
        life is what you make it.
      </p>
      <p
        className="font-primary_font text-primary_color messaging-text-l bg-grayBG p-8 md:pr-20 my-4"
        tabIndex="0"
      >
        We open the door to a life full of possibility.
      </p>
      <h3 className="heading-2">Mission</h3>
      <p className="my-4 md:w-5/6">
        For a century, we’ve remained{" "}
        <span className="italic font-bold">committed</span> to the work we do
        each day. Residents can feel proud of where they live, and their
        families can trust in the experience we create for their loved ones. Our
        team is inspired in their roles. And together, through outreach and
        volunteerism, we expand what’s possible in the greater community we
        serve.
      </p>
      <p className="font-primary_font text-primary_color messaging-text-l bg-grayBG p-8 md:pr-20 my-4">
        We create exceptional communities and services for seniors.
      </p>

      {/* VALUES  */}
      <h3 className="heading-2">Values</h3>
      <p className="my-4 md:w-5/6">
        The brand values are the{" "}
        <span className="italic font-bold">core beliefs</span> that we stand for.
        Team members live these values, are recognized for reflecting them, and when decisions need to be made, refer to them often.
      </p>
      <div className="font-primary_font text-primary_color bg-grayBG p-8 md:pr-16 my-4">
        <div>
          <div className="md:flex flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-r-0 md:w-1/4">
              brave:
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4 md:border-t">
              We do the right thing with transparency and honesty.
            </div>
          </div>
          <div className="md:flex flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              compassionate:
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              We open our hearts to others’ pain or joy.
            </div>
          </div>
          <div className="md:flex flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              inclusive:
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              We celebrate everyone from every background, culture and faith.
            </div>
          </div>
          <div className="md:flex flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              respectful:
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              We appreciate and honor individuality.
            </div>
          </div>
          <div className="md:flex flex-row">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              imaginative:
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              We view the world with fresh eyes and embrace innovation.
            </div>
          </div>
        </div>
      </div>

      {/* PERSONA */}
      <h3 className="heading-2">Persona</h3>
      <p className="my-4 md:w-5/6">
        Just like each one of us, the brand is defined by a set of relatable
        <span className="italic font-bold"> characteristics</span> called Brand
        Persona. When evaluating whether a communication is on-brand ask
        yourself if it evokes the ECS Brand Persona.
      </p>
      <figure className="mb-12">
        <img
          alt="image with words: versatile, warm, visionary, faithful, generous"
          className="block mb-1 mx-auto w-full"
          src={persona1}
        />
      </figure>

      {/* PILLARS  */}
      <h3 className="heading-2">Pillars</h3>
      <p className="my-4 md:w-5/6">
        Derived from the brand promise, pillars help make the ECS brand
        memorable and relatable. These four concepts represent{" "}
        <span className="italic font-bold">how we deliver</span> on our promise.
      </p>
      <div className="font-primary_font text-primary_color bg-grayBG p-8 md:pr-16 my-4">
        <div>
          <div className="flex flex-col md:flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-r-0 md:w-1/4">
              <p>choice:</p>
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4 md:border-t">
              It’s your life — we provide a wide variety of options, so you can
              live it on your terms.
            </div>
          </div>
          <div className="flex flex-col md:flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              <p>creativity:</p>
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              <p>
                We go above and beyond, paying attention to every detail, to
                create a colorful experience that brings out the best in you.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              <p>confidence:</p>
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              <p>
                You can count on the support, services, and independence you
                seek, no matter where you are in your journey.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              <p>connection:</p>
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              <p>
                Whether it’s caring for your physical, intellectual, spiritual,
                emotional, or social wellbeing, we make sure you feel right at
                home.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="my-8 text-2xl">
        <a
          href={withPrefix("/downloads/ECS_01_BrandFoundation_v3.pdf")}
          className="no-underline"
          download
        >
          <button className="btn" id="download-logo-mark">
            download
          </button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
          Download Brand Foundation.
        </p>
      </div>
    </section>
  )
}
/* jshint ignore:end */
export default FoundationSection
