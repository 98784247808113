import React from "react";

// images:
// none

/* jshint ignore:start */
function UsingSection() {
  return (
    <section className="text-left" id="using">
      <div className="bg-white sticky top-0">
        <h2 className="heading-1" tabIndex="0">
          Using Guidelines
        </h2>
      </div>
      <p className="my-4 md:w-5/6" tabIndex="0">
      For over a century, Episcopal Communities &amp; Services (ECS) has provided sophisticated, 
      maintenance-free, and proactive wellness living for discerning adults. Each senior living 
      community has been created to ensure the next chapter of life is as rich and vibrant as our 
      residents. In this brand guideline you’ll find the foundational elements and tools needed 
      to help express the brand authentically. We’re all brand advocates and consistent application is the key to creating brand trust and loyalty.
      </p>
      <p className="my-4 md:w-5/6">
        So, dive in and explore how the ECS brand brings the art of <span className="italic font-bold">possibility</span> to life.
      </p>
    </section>
  );
}
/* jshint ignore:end */
export default UsingSection;
