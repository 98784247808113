import React from "react"
import { withPrefix } from "gatsby"

// images
import colorExtensionGraphic from "../images/ECS_ColorExtensionGraphic.png"

function ColorSection() {
  return (
    <section className="text-left mt-20 w-full" id="color">
      <div className="bg-white sticky top-0">
        <h2 className="heading-1" tabIndex="0">
          Color
        </h2>
      </div>
      <p className="my-4 md:w-5/6" tabIndex="0">
        The ECS Brand Color Palette was{" "}
        <span className="font-bold italic">selected purposefully</span> to
        communicate the Brand Persona. The exact hues, darkness value and tonal
        range work together to capture attention and evoke an emotional
        response. The colors are designed to be used together with blue and
        white being used the most and the brighter colors used as accent. Please
        refer to the color extension sample for a better understanding of how to
        combine the colors. Color coding per location is no longer an expression
        of the brand. All colors are available to all locations for use.
      </p>
      {/* PRIMARY COLORS */}
      <h3 className="heading-2" tabIndex="0">
        Primary Color
      </h3>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        Use the primary color palette first as{" "}
        <span className="font-bold italic">key indicators</span> of the brand.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 my-4 font-second_font font-light">
        <div>
          <figure>
            <div className="bg-primary_color w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">
              Ink
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight">
            <div className="grid grid-cols-2 gap-2 w-full md:w-4/5">
              <div>HEX</div> <div>082952</div>
              <div>RGB</div> <div>027 054 100</div>
              <div>CMYK</div> <div>100 86 33 23</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-second_color w-full h-48 border border-solid border-primary_color"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">
              Frost
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight">
            <div className="grid grid-cols-2 gap-2 w-full md:w-4/5">
              <div>HEX</div> <div>ffffff</div>
              <div>RGB</div> <div>255 255 255</div>
              <div>CMYK</div> <div>0 0 0 0</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-third_color w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">
              Falcon (For use in text only)
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight uppercase">
            <div className="grid grid-cols-2 gap-2 w-full md:w-4/5">
              <div>HEX</div> <div>4f4a44</div>
              <div>RGB</div> <div>079 074 068</div>
              <div>CMYK</div> <div>35 35 40 65</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
      </div>
      {/* SECONDARY COLORS */}
      <h3 className="heading-2" tabIndex="0">
        Secondary Color
      </h3>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        The secondary palette colors are{" "}
        <span className="font-bold italic">accent colors</span> to be used for
        vibrancy and contrast.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 my-4 font-second_font font-light">
        <div>
          <figure>
            <div className="bg-s1 w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">
              Vermillion
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight">
            <div className="grid grid-cols-2 gap-2 w-full md:w-4/5">
              <div>HEX</div> <div>eb5331</div>
              <div>RGB</div> <div>218 093 062</div>
              <div>CMYK</div> <div>02 83 90 00</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-s2 w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">
              Canary
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight">
            <div className="grid grid-cols-2 gap-2 w-full md:w-4/5">
              <div>HEX</div> <div>f5eb19</div>
              <div>RGB</div> <div>244 233 082</div>
              <div>CMYK</div> <div>04 01 100 04</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-s3 w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">
              Lime
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight uppercase">
            <div className="grid grid-cols-2 gap-2 w-full md:w-4/5">
              <div>HEX</div> <div>b5ce53</div>
              <div>RGB</div> <div>181 206 083</div>
              <div>CMYK</div> <div>43 00 100 00</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-s4 w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">
              Aqua
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight uppercase">
            <div className="grid grid-cols-2 gap-2 w-full md:w-4/5">
              <div>HEX</div> <div>00a2c2</div>
              <div>RGB</div> <div>0 162 194</div>
              <div>CMYK</div> <div>50 00 18 00</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-s5 w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">
              Skyline
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight uppercase">
            <div className="grid grid-cols-2 gap-2 w-full md:w-4/5">
              <div>HEX</div> <div>e4e5e4</div>
              <div>RGB</div> <div>228 229 228</div>
              <div>CMYK</div> <div>09 06 08 00</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
      </div>
      <h3 className="heading-2" tabIndex="0">
        Color Extension
      </h3>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        This color extension scale demonstrates the{" "}
        <span className="font-bold italic">approximate percentages</span> of
        color used within a single communication. For example, this multi-page
        brand book applies this percentage guide to the total piece and not to
        one single page.
      </p>
      <figure tabIndex="0">
        <img
          alt="color extension graphic showing approximate percentages of color usage"
          className="block mx-auto w-full"
          src={colorExtensionGraphic}
        />
      </figure>
      <div className="flex flex-col md:flex-row h-16 mt-16 mb-1">
        <a
          href={withPrefix("/downloads/ECS_04A_BrandColor_v2.pdf")}
          className="no-underline"
          download
        >
          <button className="btn">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
          Download color guide.
        </p>
      </div>
    </section>
  )
}

export default ColorSection
