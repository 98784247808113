import React from "react"
import { withPrefix } from "gatsby"

// images
import masterLogo from "../images/ECS_Master_Logo_2000x500.svg"
import masterLogoReverse from "../images/ECS_Master_Logo_ColorReverse_500x500.svg"
import masterLogoWhite from "../images/ECS_Master_Logo_White_500x500.svg"
import masterLogoBlack from "../images/ECS_Master_Logo_Black_500x500.svg"
import masterLogoGrey from "../images/ECS_Master_Logo_Grey_500x500.svg"
import logoStacked from "../images/ECS_Master_Logo_Stack_1000x500.svg"
import logoHorizontal from "../images/ECS_Master_Logo_Horz_1000x500.svg"
import logoMonteCedro from "../images/MonteCedro_Logo_1000x500.svg"
import logoCovington from "../images/Covington_Logo_1000x500.svg"
import logoTwelveOaks from "../images/TwelveOaks_Logo_1000x500.svg"
import logoCanterbury from "../images/Canterbury_Logo_1000x500.svg"
import logoCareAqua from "../images/ChoiceCustomCare_Logo_Aqua_1000x500.svg"
import logoCareCanary from "../images/ChoiceCustomCare_Logo_Canary_1000x500.svg"
import logoCareLime from "../images/ChoiceCustomCare_Logo_Lime_1000x500.svg"
import logoCareVermilion from "../images/ChoiceCustomCare_Logo_Vermilion_1000x500.svg"
import logoCreativeAqua from "../images/creative-living_aqua.jpg"
import logoCreativeCanary from "../images/creative-living_canary.jpg"
import logoCreativeLime from "../images/creative-living_lime.jpg"
import logoCreativeVermilion from "../images/creative-living_vermillion.jpg"
import logoByYourSideAqua from "../images/BYS_blue.jpg"
import logoByYourSideCanary from "../images/BYS_yellow.jpg"
import logoByYourSideLime from "../images/BYS_lime.jpg"
import logoByYourSideVermilion from "../images/BYS_red.jpg"
import brandArchitecture from "../images/Brand-Arch_for-web.jpg"
import brandDos from "../images/logo-dos_for-web.jpg"
import brandDonts from "../images/logo-donts_for-web.jpg"


function LogoSection() {
  return (
    <section className="text-left w-full" id="logo">
      <div className="bg-white sticky top-0 z-10">
        <h2 className="heading-1" tabIndex="0">
          Logo Marks
        </h2>
      </div>
      <p className="my-4 md:w-5/6" tabIndex="0">
        The ECS brand mark is designed as a metaphor for hope and possibility.
        The butterfly is intentionally
        <span className="font-bold italic"> expressionistic</span>, leaving the
        emotional interpretation of the mark open to the viewer. The ECS type is
        customized to appear modern, approachable and easily legible.
      </p>
      <h3 className="heading-2" tabIndex="0">
        Primary Logos
      </h3>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        The logo is often seen as the face of the brand identity and is used in
        multiple formats. The ECS logo is the Primary Logo for preferred use.
      </p>
      <div className="grid grid-cols-1 gap-8 my-4">
        <figure tabIndex="0">
          <img
            alt="primary brand logo image"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={masterLogo}
          />
          <figcaption className="text-sm mt-1 uppercase">color logo</figcaption>
        </figure>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4">
        <figure>
          <img
            alt="master logo - color"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={masterLogoReverse}
          />
          <figcaption className="text-sm mt-1 uppercase">
            color reverse logo
          </figcaption>
        </figure>
        <figure>
          <img
            alt="master logo - grayscale"
            className="block mx-auto w-full"
            src={masterLogoWhite}
          />
          <figcaption className="text-sm mt-1 uppercase">white logo</figcaption>
        </figure>
        <figure>
          <img
            alt="logo image placeholder"
            className="block mx-auto w-full"
            src={masterLogoBlack}
          />
          <figcaption className="text-sm mt-1 uppercase">black logo</figcaption>
        </figure>
        <figure>
          <img
            alt="master logo - white"
            className="block mx-auto w-full"
            src={masterLogoGrey}
          />
          <figcaption className="text-sm mt-1 uppercase">grey logo</figcaption>
        </figure>
      </div>
      {/* SECONDARY LOGOS */}
      <h3 className="heading-2" tabIndex="0">
        Secondary Logos
      </h3>
      <p className="my-4 md:w-5/6 text-lg" tabIndex="0">
        The Secondary Logos spell out the{" "}
        <span className="font-bold italic">full name</span> and can be used when
        awareness of Episcopal Communities &amp; Services has been strongly
        established, and placing the name in accompanying body copy is not
        possible.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-4">
        <figure>
          <img
            alt="brand mark - color"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoStacked}
          />
          <figcaption className="text-sm uppercase mt-1">
            stacked logo
          </figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - grayscale"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoHorizontal}
          />
          <figcaption className="text-sm uppercase mt-1">
            horizontal logo
          </figcaption>
        </figure>
      </div>
      {/* COMMUNITY LOGOS */}
      <h3 className="heading-2" tabIndex="0">
        Community Logos
      </h3>
      <p className="my-4 md:w-5/6 text-lg" tabIndex="0">
        Use community logos when marketing specifically about a{" "}
        <span className="font-bold italic">unique location</span> and its
        service offering, or in location specific branding such as building
        signage or give-a-ways.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-4">
        <figure>
          <img
            alt="brand mark - color"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoMonteCedro}
          />
          <figcaption className="text-sm uppercase mt-1">
            MonteCedro logo
          </figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - grayscale"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoCovington}
          />
          <figcaption className="text-sm uppercase mt-1">
            The Covington logo
          </figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - color"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoTwelveOaks}
          />
          <figcaption className="text-sm uppercase mt-1">
            Twelve Oaks logo
          </figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - grayscale"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoCanterbury}
          />
          <figcaption className="text-sm uppercase mt-1">
            The Canterbury logo
          </figcaption>
        </figure>
      </div>
      {/* CARE LEVEL LOGOS */}
      <h3 className="heading-2" tabIndex="0">
        Care Level Logos
      </h3>
      <p className="my-4 md:w-5/6 text-lg" tabIndex="0">
        ECS levels of care now fold up under one umbrella service brand{" "}
        <span className="font-bold italic">Choice Custom Care</span>. When using
        this service mark alternate color options whenever possible.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-4">
        <figure>
          <img
            alt="Choice Custom Care - Aqua"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoCareAqua}
          />
          <figcaption className="text-sm uppercase mt-1">
            Choice Custom Care Logo - Aqua
          </figcaption>
        </figure>
        <figure>
          <img
            alt="Choice Custom Care Logo - Canary"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoCareCanary}
          />
          <figcaption className="text-sm uppercase mt-1">
            Choice Custom Care Logo - Canary
          </figcaption>
        </figure>
        <figure>
          <img
            alt="Choice Custom Care Logo - Lime"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoCareLime}
          />
          <figcaption className="text-sm uppercase mt-1">
            Choice Custom Care Logo - Lime
          </figcaption>
        </figure>
        <figure>
          <img
            alt="Choice Custom Care Logo - Vermillion"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoCareVermilion}
          />
          <figcaption className="text-sm uppercase mt-1">
            Choice Custom Care Logo - Vermillion
          </figcaption>
        </figure>
      </div>
      {/* CREATIVE LIVING LOGOS */}
      <h3 className="heading-2" tabIndex="0">
        Creative Living Logos
      </h3>
      <p className="my-4 md:w-5/6 text-lg" tabIndex="0">
      ECS activities live under one umbrella service brand called Creative Living. 
      When using this service mark alternate color options whenever possible, 
      and avoid color coding by community location.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-4">
        <figure>
          <img
            alt="Creative Living - Aqua"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoCreativeAqua}
          />
          <figcaption className="text-sm uppercase mt-1">
            Creative Living Logo - Aqua
          </figcaption>
        </figure>
        <figure>
          <img
            alt="Creative Living Logo - Canary"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoCreativeCanary}
          />
          <figcaption className="text-sm uppercase mt-1">
            Creative Living Logo - Canary
          </figcaption>
        </figure>
        <figure>
          <img
            alt="Creative Living Logo - Lime"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoCreativeLime}
          />
          <figcaption className="text-sm uppercase mt-1">
            Creative Living Logo - Lime
          </figcaption>
        </figure>
        <figure>
          <img
            alt="Creative Living Logo - Vermillion"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoCreativeVermilion}
          />
          <figcaption className="text-sm uppercase mt-1">
            Creative Living Logo - Vermillion
          </figcaption>
        </figure>
      </div>
      {/* BY YOUR SIDE LOGOS */}
      <h3 className="heading-2" tabIndex="0">
        By Your Side Logos
      </h3>
      <p className="my-4 md:w-5/6 text-lg" tabIndex="0">
      In addition to services ECS offers a program brand called By-Your-Side. 
      This compassion program is similar in brand architecture to Creative Living. 
      When using this service mark alternate color options whenever possible, 
      and avoid color coding by community location.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-4">
        <figure>
          <img
            alt="By Your Side - Aqua"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoByYourSideAqua}
          />
          <figcaption className="text-sm uppercase mt-1">
            By Your Side Logo - Aqua
          </figcaption>
        </figure>
        <figure>
          <img
            alt="By Your Side Logo - Canary"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoByYourSideCanary}
          />
          <figcaption className="text-sm uppercase mt-1">
            By Your Side Logo - Canary
          </figcaption>
        </figure>
        <figure>
          <img
            alt="By Your Side - Lime"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoByYourSideLime}
          />
          <figcaption className="text-sm uppercase mt-1">
            By Your Side Logo - Lime
          </figcaption>
        </figure>
        <figure>
          <img
            alt="By Your Side Logo - Vermillion"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={logoByYourSideVermilion}
          />
          <figcaption className="text-sm uppercase mt-1">
            By Your Side Logo - Vermillion
          </figcaption>
        </figure>
      </div>
      {/* BRAND ARCHITECTURE */}
      <h3 className="heading-2" tabIndex="0">
        Brand architecture
      </h3>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        The ECS Brand is made up of community locations, service brands and programatic brands. 
        These brand operate as a family of brands within an Endorsed Brand Architecture model. 
        Within this model the master brand, ECS, and the Community brands share the butterfly 
        as a graphic element. In order for these brands to remain distinct, own-able and 
        easily recognizable, the butterfly is not to be used on any product, service or 
        programmatic brand mark.
      </p>
      <div className="grid grid-cols-1 gap-8 my-4">
        <figure tabIndex="0">
          <img
            alt="brand architecture image"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={brandArchitecture}
          />
        </figure>
      </div>
      {/* BRAND DOS */}
      <h3 className="heading-2" tabIndex="0">
        Brand Dos
      </h3>
      <div className="grid grid-cols-1 gap-8 my-4">
        <figure tabIndex="0">
          <img
            alt="brand dos image"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={brandDos}
          />
        </figure>
      </div>
      {/* BRAND DON'TS */}
      <h3 className="heading-2" tabIndex="0">
        Brand Don'ts
      </h3>
      <div className="grid grid-cols-1 gap-8 my-4">
        <figure tabIndex="0">
          <img
            alt="brand donts image"
            className="block mx-auto w-full border border-solid border-primary_color"
            src={brandDonts}
          />
        </figure>
      </div>
      {/* -DOWNLOADS- */}
      <div className="flex flex-col md:flex-row h-16 mt-16 md:mb-0">
        <a
          href={withPrefix("/downloads/ECS_Logos_ECSMaster.zip")}
          className="no-underline"
          download
        >
          <button className="btn">download</button>
        </a>
        <p className="md:ml-8 md:my-4 inline-block">Master logo pack.</p>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-8">
        <a
          href={withPrefix("/downloads/ECS_Logos_Communities.zip")}
          className="no-underline"
          download
        >
          <button className="btn">download</button>
        </a>
        <p className="md:ml-8 md:my-4 inline-block">Communities logo pack.</p>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-8">
        <a
          href={withPrefix("/downloads/CCC_Logo-Pack.zip")}
          className="no-underline"
          download
        >
          <button className="btn">download</button>
        </a>
        <p className="md:ml-8 md:my-4 inline-block">
          Choice Custom Care logo pack.
        </p>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-8">
        <a
          href={withPrefix("/downloads/ECS_03_BrandMarks_v2.pdf")}
          className="no-underline"
          download
        >
          <button className="btn">download</button>
        </a>
        <p className="md:ml-8 md:my-4 inline-block">Logo usage guide PDF.</p>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-8">
        <a
          href={withPrefix("/downloads/Creative-Living_Logo-Pack.zip")}
          className="no-underline"
          download
        >
          <button className="btn">download</button>
        </a>
        <p className="md:ml-8 md:my-4 inline-block">Creative Living logo pack.</p>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-8">
        <a
          href={withPrefix("/downloads/BYU_Logo-Pack.zip")}
          className="no-underline"
          download
        >
          <button className="btn">download</button>
        </a>
        <p className="md:ml-8 md:my-4 inline-block">By Your Side logo pack.</p>
      </div>
    </section>
  )
}

export default LogoSection
