import React from "react"
import { withPrefix } from "gatsby"

// images
import acuminSample from "../images/ECS_Type_Acumin.png"
import adequateSample from "../images/ECS_Type_Adequate.png"
import arialSample from "../images/ECS_Type_Arial.png"

function TypographySection() {
  return (
    <section className="text-left mt-20 w-full" id="typography">
      <div className="bg-second_color sticky top-0">
        <h2 className="heading-1" tabIndex="0">
          Type
        </h2>
      </div>
      <p className="font-second_font font-light my-4 md:w-5/6">
        The brand fonts are <span className="font-bold italic">purposeful</span>, easily legible and supportive of our brand values and personality.
      </p>

      <h3 className="heading-2" tabIndex="0">
        Primary / Acumin
      </h3>
      <p className="font-second_font font-light my-4 md:w-5/6">
        Acumin is a versatile sans-serif typeface, designed for balance and
        extended application in small to large sizes. Use this type family with
        its varying weights for{" "}
        <span className="font-bold italic">
          body copy, subheads, and call-outs
        </span>
        .
      </p>
      <figure className="w-4/5">
        <img
          alt="brand mark - color"
          className="block mx-auto w-full"
          src={acuminSample}
        />
      </figure>

      <h3 className="heading-2 mt-16" tabIndex="0">
        Secondary / Adequate
      </h3>
      <p className="font-second_font font-light my-4 md:w-5/6">
        Adequate is a modern geometric sans serif with a tall x-height for
        clarity and legibility. Use the light weight for headlines, quotes,{" "}
        <span className="font-bold italic">large emphasis messaging</span> and{" "}
        <span className="font-bold italic">type as graphics</span>.
      </p>
      <figure className="w-4/5">
        <img
          alt="brand mark - color"
          className="block mx-auto w-full"
          src={adequateSample}
        />
      </figure>

      <h3 className="heading-2 mt-16" tabIndex="0">
        Tertiary / Arial
      </h3>
      <p className="font-second_font font-light my-4 md:w-5/6">
        Arial is a widely used sans-serif typeface available on most systems.
        Use this type family for digital assets and internal documents or
        presentations{" "}
        <span className="font-bold italic">
          when brand fonts are not available.
        </span>
        .
      </p>
      <figure className="w-4/5 mb-24">
        <img
          alt="brand mark - color"
          className="block mx-auto w-full"
          src={arialSample}
        />
      </figure>

      <div className="flex flex-col md:flex-row h-16 mt-16 mb-32 md:mb-0">
        <a
          href={withPrefix("downloads/ECS_04B_BrandType_v2.pdf")}
          className="no-underline"
          download
        >
          <button className="btn" id="download-logo-mark">
            download
          </button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
          Download type guide.
        </p>
      </div>
    </section>
  )
}

export default TypographySection
