import React from "react"
import { withPrefix } from "gatsby"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

// sections
import UsingSection from "../components/using-section"
import FoundationSection from "../components/foundation-section"
import MessagingSection from "../components/messaging-section"
import LogoSection from "../components/logo-section"
import ColorSection from "../components/color-section"
import TypographySection from "../components/typography-section"
import PhotographySection from "../components/photography-section"
import ExpressionSection from "../components/expression-section"


const IndexPage = () => (
  <Layout>
    <SEO keywords={[`brand`, `3fo`, `ECS`, `internal`]} title="Brand" />

    {/* Intro */}
    <section className="text-left pt-20 md:w-5/6" id="intro">
      <div className="bg-white sticky top-0">
        <h1 className="pt-36 md:pt-24 md:pb-4 heading-1" tabIndex="0">
          Brand Guidelines
        </h1>
      </div>
      <p className="my-4" tabIndex="0">
        Welcome to the ECS Brand Guidelines, a digital tool created to help make it easier to maintain the brand.
      </p>
      <p className="my-4" tabIndex="0">
        Here you'll find the foundational elements that create the ECS brand identity. Consistency is key in keeping the brand presence strong. Consistent and repetitive usage of these elements will create lasting recognition and a memorable connection with our audience.
      </p>
    </section>

    {/* Using Guidelines */}
    <UsingSection />

    {/* Foundation */}
    <FoundationSection />

    {/* Messaging */}
    <MessagingSection />

    {/* Logo section */}
    <LogoSection />

    {/* Color Section */}
    <ColorSection />

    {/* Typography Section */}
    <TypographySection />

    {/* Photography Section */}
    <PhotographySection />

    {/* Expression Section */}
    <ExpressionSection />

    {/* Download Section */}
    <section className="text-left mt-20 mb-48 w-full" id="download">
      <div className="bg-second_color sticky top-0">
        <h2 className="heading-1" tabIndex="0">
          Download
        </h2>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-16 mb-32 md:mb-0">
        <a
          href={withPrefix("/downloads/ECS_BrandBook_v3.pdf")}
          className="no-underline"
          download
        >
          <button className="btn">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
          Download the complete brand book.
        </p>
      </div>
      <p className="font-second_font font-light my-12 md:w-5/6">
        For questions on brand expression or messaging framework email
        <a
          href="mailto:brandQs@ecsforseniors.org"
          className="transition-colors duration-300 text-heading_color font-bold hover:text-primary_color"
        >
          {" "}
          brandQs@ecsforseniors.org
        </a>
        .
      </p>
    </section>
  </Layout>
)

export default IndexPage
