import React from "react"
import { withPrefix } from "gatsby"

// images
import lifestyle1 from "../images/Lifestyle_01_1000x1000.jpg"
import lifestyle2 from "../images/Lifestyle_02_1000x1000.jpg"
import lifestyle3 from "../images/Lifestyle_03_1000x1000.jpg"
import lifestyle4 from "../images/Lifestyle_04_1000x1000.jpg"

import abstract1 from "../images/Abstract_01_1000x1000.jpg"
import abstract2 from "../images/Abstract_02_1000x1000.jpg"
import abstract3 from "../images/Abstract_03_1000x1000.jpg"
import abstract4 from "../images/Abstract_04_1000x1000.jpg"

import brush1 from "../images/Brush_01_1000x1000.svg"
import brush2 from "../images/Brush_02_1000x1000.svg"
import brush3 from "../images/Brush_03_1000x1000.svg"
import brush4 from "../images/Brush_04_1000x1000.svg"

function PhotographySection() {
  return (
    <section className="text-left mt-20 w-full" id="photography">
      <div className="bg-second_color sticky top-0">
        <h2 className="heading-1" tabIndex="0">
          Imagery
        </h2>
      </div>
      <p className="font-second_font font-light my-4 md:w-5/6">
        The image library is a key component of the brand expression. This
        unique sets of images are used to highlight the{" "}
        <span className="font-bold italic">brand experience</span> with open
        space, exaggerated contrast and pops of color. Use these imagery sets in
        place of stock photography whenever possible.
      </p>

      {/* PRIMARY */}
      <h3 className="heading-2 my-6" tabIndex="0">
        Primary / Lifestyle
      </h3>
      <p className="font-second_font font-light my-4 md:w-5/6">
        This Lifestyle image library has been captured using actual ECS
        residents, environments and staff. These images have been color-enhanced
        to evoke the brand persona. Use these as your{" "}
        <span className="font-bold italic">first-choice images</span>.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-4">
        <figure className="">
          <img
            alt="Lifestyle Image 1"
            className="block mb-1 mx-auto w-full"
            src={lifestyle1}
          />
        </figure>
        <figure className="">
          <img
            alt="Lifestyle Image 2"
            className="block mb-1 mx-auto w-full"
            src={lifestyle2}
          />
        </figure>
        <figure className="">
          <img
            alt="Lifestyle Image 3"
            className="block mb-1 mx-auto w-full"
            src={lifestyle3}
          />
        </figure>
        <figure className="">
          <img
            alt="Lifestyle Image 4"
            className="block mb-1 mx-auto w-full"
            src={lifestyle4}
          />
        </figure>
      </div>

      {/* SECONDARY */}
      <h3 className="heading-2 my-6" tabIndex="0">
        Secondary / Abstract
      </h3>
      <p className="font-second_font font-light my-4 md:w-5/6">
        These images are used to demonstrate choice, adventure, imagination and
        to reinforce personal expression. There are nine abstract images
        available as <span className="font-bold italic">secondary support</span>{" "}
        imagery. Use these in conjunction with Lifestyle imagery or solid brand
        color fields. Never use abstract imagery alone.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-4">
        <figure className="">
          <img
            alt="Abstract Image 1"
            className="block mb-1 mx-auto w-full"
            src={abstract1}
          />
        </figure>
        <figure className="">
          <img
            alt="Abstract Image 2"
            className="block mb-1 mx-auto w-full"
            src={abstract2}
          />
        </figure>
        <figure className="">
          <img
            alt="Abstract Image 3"
            className="block mb-1 mx-auto w-full"
            src={abstract3}
          />
        </figure>
        <figure className="">
          <img
            alt="Abstract Image 4"
            className="block mb-1 mx-auto w-full"
            src={abstract4}
          />
        </figure>
      </div>

      {/* TERTIARY */}
      <h3 className="heading-2 my-6" tabIndex="0">
        Tertiary / Brush Stroke
      </h3>
      <p className="font-second_font font-light my-4 md:w-5/6">
        There are four brush stroke graphics available to be used as{" "}
        <span className="font-bold italic">texture</span>. Layer these graphics
        to blend primary and secondary imagery into white space or backgrounds —
        eliminating boundaries. Brush strokes can also be used in color as a
        flag style call-out.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-4">
        <figure className="">
          <img
            alt="Brushstroke Image 1"
            className="block mb-1 mx-auto w-full"
            src={brush1}
          />
        </figure>
        <figure className="">
          <img
            alt="Brushstroke Image 2"
            className="block mb-1 mx-auto w-full"
            src={brush2}
          />
        </figure>
        <figure className="">
          <img
            alt="Brushstroke Image 3"
            className="block mb-1 mx-auto w-full"
            src={brush3}
          />
        </figure>
        <figure className="">
          <img
            alt="Brushstroke Image 4"
            className="block mb-1 mx-auto w-full"
            src={brush4}
          />
        </figure>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-16 mb-32 md:mb-0">
        <a
          href={withPrefix("downloads/ECS_Brand-Image-Reference.pdf")}
          className="no-underline"
          download
        >
          <button className="btn" id="download-logo-mark">
            download
          </button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
          Download image guide.
        </p>
      </div>
    </section>
  )
}

export default PhotographySection
