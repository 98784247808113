import React from "react"
import { withPrefix } from "gatsby"

// images:
// none

/* jshint ignore:start */
function MessagingSection() {
  return (
    <section className="text-left" id="messaging">
      <div className="bg-white sticky top-0">
        <h2 className="heading-1" tabIndex="0">
          Messaging
        </h2>
      </div>
      <p className="my-4 md:w-5/6" tabIndex="0">
        As brand advocates, our collective goal is to ensure consistency of
        expression when conveying the mission, values, and benefits of ECS. It’s
        about <span className="font-bold italic">how we write</span>, not what
        we write &mdash; and about why we’re committed to delivering a distinct
        and memorable experience for our audience.
      </p>

      {/* TAGLINE */}
      <h3 className="heading-2" tabIndex="0">
        Tagline
      </h3>
      <p className="my-4 md:w-5/6" tabIndex="0">
        Our tagline acts as a verbal logo. It tells people our “why”{" "}
        <span className="font-bold italic">simply and memorably</span>. And for
        us, our tagline is best expressed as our Brand Essence.
      </p>
      <p
        className="font-primary_font text-primary_color messaging-text-xl bg-grayBG p-4 md:pr-20 my-4"
        tabIndex="0"
      >
        The Art of Possibility
      </p>

      {/* VOICE & TONE */}
      <h3 className="heading-2" tabIndex="0">
        Voice &amp; Tone
      </h3>
      <p className="my-4 md:w-5/6" tabIndex="0">
        To make a <span className="font-bold italic">deeper connection</span>{" "}
        with our audience, we will speak to them as if they are sitting directly
        in front of us. It helps move the conversation from “we” to “you” and
        “our” to “your.” When we communicate our Brand Voice and Tone should
        sound like this:
      </p>
      <div className="font-primary_font text-primary_color bg-grayBG p-8 md:pr-16 my-4">
        <div>
          <div className="md:flex flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-r-0 md:w-1/4">
              vibrant:
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4 md:border-t">
              We’ll make our language reflective of our seniors: active,
              energetic, and lively.
            </div>
          </div>
          <div className="md:flex flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              genuine:
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              We’ll be natural, honest and positive. We say what we mean in a
              warm and welcoming way.
            </div>
          </div>
          <div className="md:flex flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              insightful:
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              We’ll use insights to drive the narrative, championing our unique
              position in the market and building confidence through expertise.
            </div>
          </div>
          <div className="md:flex flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              uplifting:
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              Our stories will be creative, hopeful, and progressive &mdash;
              never conventional or expected.
            </div>
          </div>
          <div className="md:flex flex-row">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              relatable:
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              We’ll be as engaging, empathetic, and approachable as the
              communities we support.
            </div>
          </div>
        </div>
      </div>

      {/* KEY MESSAGES */}
      <h3 className="heading-2" tabIndex="0">
        Key Messages
      </h3>
      <p className="my-4 md:w-5/6" tabIndex="0">
        It&rsquo;s the strong reasons-to-believe that will{" "}
        <span className="font-bold italic">resonate emotionally </span>
        with our audience and build long lasting brand loyalty. Focus on these
        key messages aligned to our Brand Pillars:
      </p>
      <div className="font-primary_font text-primary_color bg-grayBG p-8 md:pr-16 my-4">
        <div>
          <div className="md:flex flex-row pb-4 md:pb-0 text-heading_color font-bold">
            <div className="valuesCellLeft border md:border-r-0 md:w-1/4">
              brand pillar
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4 md:border-t">
              reasons-to-believe
            </div>
          </div>
          <div className="md:flex flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              choice:
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              Enjoy the flexibility to personalize your life and care plan the
              way you want it &mdash; from start to finish. Because we see you
              for the unique individual you are.
            </div>
          </div>
          <div className="md:flex flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              creativity:
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              Live beyond daily needs and be as creative, active, or adventurous as you want to be with Creative Living designed for your life.
            </div>
          </div>
          <div className="md:flex flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              confidence:
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              You &mdash; and your family &mdash; can count on compassion,
              advocacy, and a broad spectrum of exceptional senior care and
              services. Because we proudly bring a century&rsquo;s worth of
              community living expertise.
            </div>
          </div>
          <div className="md:flex flex-row pb-4 md:pb-0">
            <div className="valuesCellLeft border md:border-t-0 md:border-r-0 md:w-1/4">
              connection:
            </div>
            <div className="valuesCellRight border-t-0 border-l border-r border-b md:w-3/4">
              Here, you&rsquo;ll experience a healthy, compassionate and
              thriving environment, designed to help you build new relationships
              and connect socially. Because community is at the heart of all we
              do.
            </div>
          </div>
        </div>
      </div>

      {/* DOWNLOAD */}
      <div className="my-8 text-2xl">
        <a
          href={withPrefix("/downloads/ECS_02_BrandMessaging_v2.pdf")}
          className="no-underline"
          download
        >
          <button className="btn" id="download-logo-mark">
            download
          </button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
          Download Messaging Guide.
        </p>
      </div>
    </section>
  )
}
/* jshint ignore:end */
export default MessagingSection
