import React from "react"
import { withPrefix } from "gatsby"

// images
import combiningElements from "../images/CombiningElements_2000x1500.png"

function ExpressionSection() {
  return (
    <section className="text-left mt-20 w-full" id="expression">
      <div className="bg-second_color sticky top-0">
        <h2 className="heading-1" tabIndex="0">
          Expression
        </h2>
      </div>
      <p className="font-second_font font-light my-4 md:w-5/6">
        The brand expression is made up of all the ways you can experience the ECS brand, from our logo mark to the website — or simply the way we say "hello." Think about the light streaming in through the windows when you
        enter a community, or the smell of the flowers planted near your
        favorite walking path. Each of these contribute to the overall
        perception of the brand.
      </p>
      <p className="font-second_font font-light my-4 md:w-5/6">
        The following visual is a reference to be used when{" "}
        <span className="font-bold italic">combining elements</span>, such as
        color, type and imagery, into tangible communications.
      </p>
      <figure className="w-full">
        <img
          alt="graphic showing combination of elements"
          className="block mx-auto w-full"
          src={combiningElements}
        />
      </figure>

      <div className="flex flex-col md:flex-row h-16 mt-16 mb-32 md:mb-0">
        <a
          href={withPrefix("/downloads/ECS_04_BrandExpression_v2.pdf")}
          className="no-underline"
          download
        >
          <button className="btn">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
          Download brand samples.
        </p>
      </div>
    </section>
  )
}

export default ExpressionSection
